<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col">
     <div class="whiteBack">

        <label>Delete Account</label>
        <div v-if="!showDelete">
          <button class="btn btn__error btn__small mt-2 mb-2" @click="deleteAccount()">Delete Account</button>
        </div>
        <div v-if="showDelete" class="flex justify-space-between">
          <button class="btn btn__outlined btn__small mt-2 mb-2 mr-3" @click="cancelDelete()">Cancel</button>
          <button class="btn btn__error btn__small mt-2 mb-2" @click="deleteAccountForReal()">Yes, Delete Account</button>
          
        </div>
        <div v-if="showDelete" class="mt-2">
          <p class="caption">Warning! This cannot be undone.</p>
        </div>
      </div>  
    </div>
    <div class="dashboard__container--body--col">
       <div class="whiteBack">
        <label>Log Out</label>
        <div>
          <button class="btn btn__outlined btn__small mt-2 mb-2" @click="logout()">Log Out</button>
          </div>
       </div>
     </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'

export default {
  name: 'accountSettings',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
  data: () => ({
    showDelete: false,
  }),
  components: {
    Loader,
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    deleteAccountForReal() {
      const user = this.currentUser
      console.log('deleting')

      user.delete().then(() => {

      this.$store.dispatch('logout')
      this.$router.push('/')

      }).catch((error) => {
      console.log(error)
      });
    },
    deleteAccount() {
      console.log('delete')
      this.showDelete = true
    },
    cancelDelete() {
      console.log('cancel delete')
      this.showDelete = false
      this.settingsExpand = false
      
    },
  }
}
</script>